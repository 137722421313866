import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import TextWrapAroundImg from "../../components/textWrapAroundImg";
import HeaderWithTextAndImageSmall from "../../components/headerWithTextAndImageSmall";
import Head from "../../components/head";
import TSSCraneStyles from "./tssCrane.module.css";
import TssUgaStyles from "./tssUga.module.css";

import headlineStyles from "../../components/headline.module.css";

export const query = graphql`
  query {
    allContentfulBio(filter: { program: { eq: "ONNY Apprentice" } }) {
      edges {
        node {
          name
          titleOrInstrument
          bioImage {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulTssOnnyPage {
      edges {
        node {
          headerImage {
            file {
              url
            }
          }
          headerHeadline {
            headerHeadline
          }
          headerSubheadline {
            headerSubheadline
          }
          section1Headline {
            section1Headline
          }
          section1Image {
            file {
              url
            }
          }
          section1Paragraph {
            section1Paragraph
          }
          section2Image {
            file {
              url
            }
          }
          section2Headline
          section2Paragraph {
            section2Paragraph
          }
          apprenticeSectionHeadline
        }
      }
    }
  }
`;

const TssOnny = props => {
  return (
    <>
      <Head title="TSS training | ONNY"></Head>
      <div
        className={TSSCraneStyles.backgroundImageContainer}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${props.data.allContentfulTssOnnyPage.edges[0].node.headerImage.file.url})`,
        }}
      >
        <div>
          <h1 className={TSSCraneStyles.heading}>
            {
              props.data.allContentfulTssOnnyPage.edges[0].node.headerHeadline
                .headerHeadline
            }
          </h1>
          <p className={TSSCraneStyles.subHeading}>
            {
              props.data.allContentfulTssOnnyPage.edges[0].node
                .headerSubheadline.headerSubheadline
            }
          </p>
          <Link to="/donate" className={TSSCraneStyles.primaryBtn}>
            Help us
          </Link>
        </div>
      </div>

      <div
        className={headlineStyles.headline}
        style={{ backgroundColor: "#fff" }}
      >
        <h2>
          {
            props.data.allContentfulTssOnnyPage.edges[0].node.section1Headline
              .section1Headline
          }
        </h2>
      </div>
      <TextWrapAroundImg
        imgRight={
          props.data.allContentfulTssOnnyPage.edges[0].node.section1Image.file
            .url
        }
        paragraphHeader={false}
        lightBackground={true}
      >
        {" "}
        <p style={{ whiteSpace: "pre-wrap" }}>
          {
            props.data.allContentfulTssOnnyPage.edges[0].node.section1Paragraph
              .section1Paragraph
          }
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Link
            to="/donate"
            style={{
              textDecoration: "none",
              color: "#ba0c2f",
              fontWeight: "600",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            {" "}
            Help us support apprentices{" "}
          </Link>
        </div>
      </TextWrapAroundImg>

      <HeaderWithTextAndImageSmall
        imgRight={
          props.data.allContentfulTssOnnyPage.edges[0].node.section2Image.file
            .url
        }
        paragraphHeader={
          props.data.allContentfulTssOnnyPage.edges[0].node.section2Headline
        }
        lightBackground={true}
      >
        {" "}
        <p style={{ whiteSpace: "pre-wrap" }}>
          {
            props.data.allContentfulTssOnnyPage.edges[0].node.section2Paragraph
              .section2Paragraph
          }
        </p>
      </HeaderWithTextAndImageSmall>

      <section className={TssUgaStyles.UgaScholarSection}>
        <div className={TssUgaStyles.UgaProfessorContainer}>
          <h3>
            {
              props.data.allContentfulTssOnnyPage.edges[0].node
                .apprenticeSectionHeadline
            }
          </h3>
          <div>
            {props.data.allContentfulBio.edges.map((edge, index) => {
              return (
                <div key={index} className={TssUgaStyles.scholar}>
                  <Link to={`/bio/${edge.node.name}`}>
                    <div>
                      <img src={edge.node.bioImage.file.url}></img>
                      <div>
                        <h4>{edge.node.name}</h4>
                        <p>{edge.node.titleOrInstrument}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default TssOnny;
